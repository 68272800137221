import React from 'react';
import Layout from '../components/layout';
import Products from '../components/products';
import SEO from '../components/seo';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import Qualities from '../components/qualities';
import Svg from '../components/svg';
import StatsSmall from '../components/stats-small';

export default ({ pageContext: { content, productGroup, pages, lang, texts, langs } }) => {
  const data = productGroup.data;
  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        image={data.seo_image}
      />
      <div className='layer-top layer-product-hero'>
        <A160 className='products-liquid-1' />
        <div className='relative'>
          <div className='wrap-full pl-xl pr-xl'>
            <div className='product-hero flex'>
              <div className='left'>
                <h1
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  dangerouslySetInnerHTML={{ __html: data.quality_title }}
                />
                <h3
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  className='h3'
                  dangerouslySetInnerHTML={{ __html: data.quality_subtitle }}
                />
                <div
                  className='text'
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  dangerouslySetInnerHTML={{ __html: data.quality_text }}
                />
              </div>
              {/* <div
                data-sal='slide-left'
                data-sal-duration='1000'
                className='img'
              >
                <img
                  src={data.quality_image}
                  alt={data.quality_title}
                  title={data.quality_title}
                />
              </div> */}
              <div className='right'>
                <A400 className='product-liquid-2' />
                <img
                  data-sal='slide-down'
                  data-sal-duration='1000'
                  className='product-image'
                  src={data.quality_image}
                  alt={data.quality_title}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='wrap-full pl-xl pr-xl product-applications-title'>
        <h4
          data-sal='slide-up'
          data-sal-duration='1000'
          className='h4'
          dangerouslySetInnerHTML={{
            __html: data.applications_subtitle,
          }}
        />
      </div>
      <StatsSmall data={data} />
      <div className='layer'>
        <A160 className='product-liquid-5' />
        <A400 className='product-liquid-6' />
        <div className='wrap-full pl-xl pr-xl'>
          <h2
            className='h2 h2--secondary'
            data-sal='slide-left'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{
              __html: data.packaging_text,
            }}
          />
        </div>
        <div className='wrap-full pl-s pr-s'>
          <div className='weights'>
            {data.packaging_blocks.map((packaging, index) => {
              let n = index + 1;
              return (
                <div
                  data-sal-delay={index * 200}
                  data-sal='slide-up'
                  data-sal-duration='1000'
                  key={index}
                  className={`weight-${n}`}
                >
                  <Svg name='weight-1' className='weight' />
                  <Svg name='triangle' className='triangle' />
                  <div>
                    {packaging}
                    <span>kg</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='wrap-full pl-xl pr-xl'>
        <h2
          className='h2 h2--secondary'
          data-sal='slide-left'
          data-sal-duration='1000'
          dangerouslySetInnerHTML={{
            __html: data.products_table_title,
          }}
        />
      </div>
      <Products productGroup={productGroup} pages={pages} lang={lang} texts={texts} />
      <img
        data-sal='fade'
        data-sal-duration='1000'
        className='mobile-hide'
        src={data.products_image_desktop}
        alt=''
        title=''
      />
      <img
        data-sal='fade'
        data-sal-duration='1000'
        className='desktop-hide'
        src={data.products_image_mobile}
        alt=''
        title=''
      />
      <div className='layer layer-products-texts'>
        <A400 className='products-liquid-3' />
        <A160 className='products-liquid-4' />
        <div className='wrap-full pl-xl pr-xl'>
          {data.safety_blocks.map((block, index) => {
            return (
              <div key={index} className='group group-3 group-inovations'>
                <div className='left'>
                  <div
                    data-sal='slide-right'
                    data-sal-duration='1000'
                    className='h3'
                    dangerouslySetInnerHTML={{ __html: block.title }}
                  />
                </div>
                <div
                  data-sal='slide-left'
                  data-sal-duration='1000'
                  className='right'
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Qualities data={texts} />
    </Layout>
  );
};
