import React from "react"
import Svg from "./svg"

const StatsSmall = ({ data }) => (
    <div className="blocks-small">
        <div>
            <div
                data-sal="slide-up"
                data-sal-duration="600">
                {data.category_block_1_icon ? (
                    <img
                    src={data.category_block_1_icon}
                    alt={data.category_block_1_title}
                    title={data.category_block_1_title}
                    />
                ) : (
                    <Svg name="block-7" />
                )}
                <b>{data.category_block_1_title}</b>
                {data.category_block_1_text !== null && data.category_block_1_text}
            </div>
        </div>
        <div>
            <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="600">
                {data.category_block_2_icon ? (
                    <img
                    src={data.category_block_2_icon}
                    alt={data.category_block_2_title}
                    title={data.category_block_2_title}
                    />
                ) : (
                    <Svg name="block-8" />
                )}
                <b>{data.category_block_2_title}</b>
                {data.category_block_2_text !== null && data.category_block_2_text}
            </div>
        </div>
        <div>
            <div
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-duration="600">
                {data.category_block_3_icon ? (
                    <img
                    src={data.category_block_3_icon}
                    alt={data.category_block_3_title}
                    title={data.category_block_3_title}
                    />
                ) : (
                    <Svg name="block-9" />
                )}
                <b>{data.category_block_3_title}</b>
                {data.category_block_3_text !== null && data.category_block_3_text}
            </div>
        </div>
        <div>
            <div
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="600">
                {data.category_block_4_icon ? (
                    <img
                    src={data.category_block_4_icon}
                    alt={data.category_block_4_title}
                    title={data.category_block_4_title}
                    />
                ) : (
                    <Svg name="block-10" />
                )}
                <b>{data.category_block_4_title}</b>
                {data.category_block_4_text !== null && data.category_block_4_text}
            </div>
        </div>

    </div>
)

export default StatsSmall
