import React from "react"
import Svg from "./svg"
import MilkLink from "./milk-link"

const Products = ({ productGroup, pages, lang, texts }) => {
    // const products = pages.filter(page => page[lang].template === 'products')[0][lang].items;
    const products = productGroup.items;
    return (
        <table
            data-sal="slide-up"
            data-sal-duration="1000"
            className="products-table">
            <tbody>
                <tr>
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_1 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_2 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_3 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_4 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_5 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_6 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_7 }} />
                    <th dangerouslySetInnerHTML={{__html: texts.products_columns_8 }} />
                </tr>
                {products.map((product, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div dangerouslySetInnerHTML={{__html: product.subtitle }} />
                                <div>
                                    <b dangerouslySetInnerHTML={{__html: product.title }} />
                                </div>
                            </td>
                            <td data-name={texts.products_columns_2} dangerouslySetInnerHTML={{__html: product.info.fat }} />
                            <td data-name={texts.products_columns_3} dangerouslySetInnerHTML={{__html: product.info.protein }} />
                            <td data-name={texts.products_columns_4} dangerouslySetInnerHTML={{__html: product.info.lactose }} />
                            <td data-name={texts.products_columns_5} dangerouslySetInnerHTML={{__html: product.info.moistuer }} />
                            <td data-name={texts.products_columns_6} dangerouslySetInnerHTML={{__html: product.info.ash }} />
                            <td data-name={texts.products_columns_7} dangerouslySetInnerHTML={{__html: product.info.ph }} />
                            <td data-name={texts.products_columns_8}>
                                <span dangerouslySetInnerHTML={{__html: product.info.tpc }} />
                                <MilkLink to={`/${lang}${productGroup.url}${product.url}`} className="btn-next">
                                    <Svg name="right" />
                                </MilkLink>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default Products
